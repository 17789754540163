
<template>
    <div class="comp-hero">
      <p 
        v-if="typeof propPreheadline !== 'undefined' && propPreheadline !== ''"
        class="preheadline"
      >{{ propPreheadline }}</p>

      <component
        :is="propHeadlineLevel"
        class="typography-hero typography-editorial-big"
      >        
        <transition-group
          v-for="(singleHeroText, singleHeroTextIndex) in propTextHero"
          :key="`single-hero-text-${singleHeroTextIndex}`"
          :name="propApplyAnimation ? 'transition-text-by-word' : ''"
          tag="p"
          appear
          @after-enter="transitionAfterEnter"
          :duration="500"
        >
          <span
            v-for="(singlHeroWord, singlHeroWordIndex) in returnSingleWordsArray(singleHeroText)"
            :key="`single-hero-word-${singlHeroWordIndex}`"
            :style="returnTransitionDelay(singleHeroTextIndex, singlHeroWordIndex)"
            class="single-hero-word"
          >{{ singlHeroWord }} </span>
        </transition-group>
      </component>

      <p
        v-if="typeof propTextDescription !== 'undefined' && propTextDescription !== ``"
        class="hero-description typography-editorial-small"
      >{{ propTextDescription }}</p>
    </div>
</template>

<script>
export default {
  name: 'CompHero',
  
  props: {
    propApplyAnimation: {
      type: Boolean,
      required: false,
      default: true
    },
    propPreheadline: {
      type: String,
      required: false
    },
    propTextHero: {
      type: Array,
      required: true
    },
    propTextDescription: {
      type: String,
      required: false
    },
    propHeadlineLevel: {
      type: String,
      required: true,
      validator: function (value) {
        // The value must match one of these strings
        return ['h1', 'h2', 'h3', 'span'].includes(value)
      }
    }
  },

  methods: {
    returnSingleWordsArray (passedText) {
      return passedText.split(' ')
    },

    returnTransitionDelay (passedParentIndex, passedWordIndex) {
      let delayMultiplyier = 75
      let delayRandom = (Math.random() * delayMultiplyier)
      let delayBase = (passedWordIndex * delayMultiplyier)
      let delayNextSentence = delayMultiplyier * 3

      if (passedParentIndex !== 0) {
        let delayNumberPreviousTextPassage = ((this.returnSingleWordsArray(this.propTextHero[passedParentIndex - 1]).length) * delayMultiplyier)

        return `transition-delay: ${delayNumberPreviousTextPassage + delayNextSentence + delayBase + delayRandom}ms`
      }
      
      return `transition-delay: ${delayBase + delayRandom}ms`
    },

    transitionAfterEnter (el) {
      el.style = 'transition-delay: 0ms';
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/css/scss-variables.scss';
  
  // section:first-of-type .comp-hero {
  //   margin-top: var(--hero-half-screen-offset);
  // }

  .comp-hero {
    display: flex;
    flex-direction: column;
    place-content: center;

    *:not(.single-hero-word, .preheadline) + *:not(.single-hero-word) {
      margin-top: var(--spacer-padding);
    }

    @media (min-width: $mediaquery-tablet-portrait) {
      grid-column: 1 / -1;
      grid-row: 1;
    }

    @media (min-width: $mediaquery-laptop) {
      grid-column: 2 / span 2;
      grid-row: 1;
    }
    
    .preheadline + * {
      margin-top: .2em;
    }

    .hero-description {
      width: 100%;
      max-width: 45ch;
      text-align: center;
      margin-inline: auto;
    }
  }

  .typography-hero {

    p {
      column-gap: 0.2em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    // span {
    //   display: inline-block;
    // }
  }

  .transition-text-by-word-enter-active,
  .transition-text-by-word-leave-active {
    transition:
      transform .25s ease-in-out,
      opacity .25s ease-in-out;
  }

  .transition-text-by-word-enter,
  .transition-text-by-word-leave-to {
    opacity: 0;
    transform: translateY(10%);
  }
</style>
